import React from 'react'
import {FaFacebook, FaYoutube, FaInstagram, FaLinkedin, FaTwitter} from 'react-icons/fa'

export default [
    {
        icon: <FaLinkedin/>,
        url: 'https://www.linkedin.com/company/chata.ai/'
    },
    {
        icon: <FaTwitter/>,
        url: 'https://twitter.com/thechataHQ'
    },
    {
        icon: <FaInstagram/>,
        url: 'https://www.instagram.com/thechatahq/'
    },
    {
        icon: <FaFacebook/>,
        url: 'https://www.facebook.com/thechataHQ/'
    },
    {
        icon: <FaYoutube/>,
        url: 'https://www.youtube.com/channel/UChVUUi9ETWnYf-xZPuY0u8g'
    }
]