export default[
    
    {
        path: "/",
        text: "SOLUTIONS",
        levels: [
            {
                path: "/autoql",
                text: "AutoQL"
            },
            {
                path: "/works",
                text: "How It Works"
            }
        ]
    },
    {
        path: "/works",
        text: "IMPLEMENTATION",
        levels: [
            {
                path: "/datamessanger",
                text: "Data messanger"
            }
        ]
    },
    {
        path: "/about",
        text: "COMPANY",
        levels: [
            
        ]
    }
]