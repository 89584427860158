import React from "react"
import styles from "../css/banner.module.css"
const Banner = ({ title, subTitle, info, children, styleContent, direction="", home = "", icon = "", secondBanner = "" }) => {
  let finalBannerStyle = home === "" ? styles.banner : styles.bannerEspecial
  let finalContentClass = styleContent === "" ? styles.contentBannerDefault : styleContent
  let div1 = direction.includes("left") ? styles.orderMain : styles.orderSecond
  let div2 = direction.includes("left") ? styles.orderSecond : styles.orderMain
  // let finalCCS = direction.includes("right") ? styles.complementRight : styles.complementRightEmpty
  console.log(secondBanner)
  return (
    
    <div className={finalContentClass}>
      <div className={div1 + ' ' + finalBannerStyle}>
        <h1 className={direction}> {title}</h1>
        <h2 className={direction}>{subTitle}</h2>
        <p className={direction} dangerouslySetInnerHTML={{ __html: info }} />
        {children}
      </div>
      { secondBanner === "true" && <div className={div2 + ' ' + styles.contentBanner2}>
      <p>{icon}</p>
      </div>
      }
    </div>
  )
}


export default Banner
