import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import './layout.css'
// import styles from './navbar.module.css'
// import '../sass/layout.scss'
// import styles from '../sass/layout.module.scss'
// import 'bootstrap/dist/css/bootstrap.min.css'
const Layout = ({children, newLink = false}) => {
    return (
        <>
            <Navbar newLink={newLink} />
            {children}
            
            <Footer />
            
        </>
    )
}
export default Layout