import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const getData = graphql`
  query {
    site {
      siteMetadata {
        author
        title
        description
      }
    }
  }
`

const SEO = ({ titlePage, descriptionPage }) => {
  const { site } = useStaticQuery(getData)

  const {
    author,
    title,
    description,
  } = site.siteMetadata
  return (
    <Helmet htmlAttributes={{ lang: "en" }} title={`${titlePage || title}`}>
      <meta name="description" content={descriptionPage || description} />
      {/* facebook cards */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:author" content={author} />
      <meta property="og:description" content={description} />
    </Helmet>
  )
}

export default SEO