import React from "react"
import styles from "../css/hero.module.css"

const StyledHero = ({ img, className, children, home, id }) => {
  let styleHome = home === "true" ? styles.homeFull : styles.homeHalf
  return (
    <div className={styleHome} home={home} id={id} key={id}>
      {children}
    </div>
    /*<BackgroundImage
      className={className}
      home={home}
    >
      {children}
    </BackgroundImage>*/
  )
}
export default StyledHero
/*export default styled(StyledHero)`
  min-height: ${StyledHero.home ? "calc(100vh - 62px)" : "50vh"};
  background-position: center;
  background-size: cover;
  opacity: 1 !important;
  display: flex;
  justify-content: center;
  align-items: center;
`*/
/*const max = styled.div`
min-height: ${StyledHero.home ? "calc(100vh - 62px)" : "50vh"};
background-position: center;
background-size: cover;
opacity: 1 !important;
display: flex;
justify-content: center;
align-items: center;*
`

export default StyledHero
/* background: ${props =>
props.home
? "linear-gradient(rgba(63, 208, 212, 0.7), rgba(0, 0, 0, 0.7))"
: "none"}*/
