import React from 'react'
import "../css/mailchamp.module.css"


const MailChamp = () => {
        return (
            <div>
                <div>
                    <form action="https://chata.us16.list-manage.com/subscribe/post?u=9cf310356ba2dedebdfa3d701&amp;id=16d8306c62" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" method="post" target="_blank">
                    <div id="mc_embed_signup_scroll">
                        <h2 className="h2">Learn more about the next generation of data exploration</h2>
                        <div className="indicates-required"><span className="asterisk">*</span> indicates required field</div>
                        <div className="mc-field-group">
                            <label htmlFor="mce-EMAIL">Email Address  <span className="asterisk">*</span>
                        </label>
                            <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" />
                        </div>
                        <div className="mc-field-group">
                        <label htmlFor="mce-FNAME">First Name  <span className="asterisk">*</span>
                        </label>
                            <input type="text" name="FNAME" className="required" id="mce-FNAME" />
                        </div>
                        <div className="mc-field-group">
                            <label htmlFor="mce-LNAME">Last Name  <span className="asterisk">*</span>
                        </label>
                            <input type="text" name="LNAME" className="required" id="mce-LNAME" />
                        </div>
                        <div className="mc-field-group">
                            <label htmlFor="mce-MMERGE4">Company </label>
                            <input type="text" name="MMERGE4" className="" id="mce-MMERGE4" />
                        </div>
                        <div className="mc-field-group size1of2">
                            <label htmlFor="mce-MMERGE5">Phone Number </label>
                            <input type="text" name="MMERGE5" className=""  id="mce-MMERGE5" />
                        </div>
                        <div className="mc-field-group">
                            <label htmlFor="mce-MMERGE6">Industry </label>
                            <input type="text"  name="MMERGE6" className="" id="mce-MMERGE6" />
                        </div>
                    
                    
                    </div>
                    <div className="displayFlex">
                        <a href="#" rel="noreferrer noopener" className="btn-secondary container-btns btnEspecial">Cancel</a>
                        <input type="submit" className="btn-white container-btns btnEspecial" value="Subscribe" name="subscribe" id="mc-embedded-subscribe"/>
                    </div>
                    
                    </form>
                </div>
            </div>
        );
}
export default MailChamp