import React, { useState } from 'react'
import links from '../constans/links'
import { FaAlignRight } from "react-icons/fa"
import styles from "../css/navbar.module.css"
import logo from "../images/logo_home.png"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import MailChamp from "./MailChamp"
import scrollTo from 'gatsby-plugin-smoothscroll';
import {useStaticQuery, graphql} from 'gatsby'
const getSpoths = graphql`
query{
  solutions:allContentfulSolutions(sort:{fields: order}){
    edges{
      node{
        slug
        titleMenu
      }
    }
  }
}
`
const Navbar = (newLink2) => {
    const [isOpen, setNav] = useState()
    const response = useStaticQuery(getSpoths)
    const spothsQuery = response.solutions.edges
    console.log(spothsQuery)
    const toggleNav = () => {
        setNav(isOpen => !isOpen)
    }
    return (
        /*<nav>
            <ul>
                <li>
                    <Link to="/">Home</Link>
                </li>
                <li>
                    <Link to="/servicios">Servicios</Link>
                </li>
                <li>
                    <Link to="/portafolio">Portafolio</Link>
                </li>
                <li>
                    <Link to="/contacto">Contacto</Link>
                </li>
            </ul>
        </nav>*/
        
        <nav className={styles.navbar}>
        <div className={styles.navCenter}>
          <div className={styles.navHeader}>
            <AniLink fade to="/"><img src={logo} alt="backroads logo" className={styles.logo} /></AniLink>
            <button type="button" className={styles.logoBtn} onClick={toggleNav}>
              <FaAlignRight className={styles.logoIcon} />
            </button>
          </div>
          <ul
          className={
            isOpen
              ? `${styles.navLinks} ${styles.showNav}`
              : `${styles.navLinks}`
          }
        >
          {/* <li><a href="#" className={styles.abs}>Solutions ▾</a>
            <ul className={styles.submenu}>
            {
            spothsQuery.map(({ node },index) => {
                let finalUrl = `/solutions/${node.slug}`
                return (
                  <li key={index}>
                    <AniLink fade to={finalUrl}>
                    {node.titleMenu}
                    </AniLink>
                  </li>
                )
              })
            }
            </ul>
          </li> */}
          {links.map((item, index) => {
            if (item.levels.length > 0){
              return (
                /*<li key={index}>
                    <AniLink fade to={item.path}>{item.text}</AniLink>
                </li>*/
                <li><a href="#" className={styles.abs}>{item.text} ▾</a>
                  <ul className={styles.submenu}>
                  {
                  item.levels.map((sublevel, index) => {
                      // let finalUrl = `${sublevel.path}`
                      return (
                        <li key={index}>
                          <AniLink fade to={sublevel.path}>
                          {sublevel.text}
                          </AniLink>
                        </li>
                      )
                    })
                  }
                  </ul>
                </li>
              )
            } else {
              return (
                <li key={index}>
                    <AniLink fade to={item.path}>{item.text}</AniLink>
                </li>
              )
            }
              
          })} 
          {/* <li key="100"> <a href="#popup1" className="learnmore" rel="noreferrer noopener">Learn More</a></li> */}
        </ul>
        </div>
        <div id="popup1" className={styles.overlay}>
          <div className={styles.popup}>
            <div className={styles.content}>
              <div id="mc_embed_signup">
                <MailChamp/>
              </div>
            </div>
          </div>
        </div>
      </nav>
    )
}
export default Navbar